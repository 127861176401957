import React from 'react'

import './list.scss'

const TeamList = ({ children, title }) => (
  <div className='b-team'>
    <div className='b-team-title'>
      <h2 className='b-main-title m-2'>{title}</h2>
    </div>
    <div className='b-team-items'>
      {children}
    </div>
  </div>
)

export default TeamList
