import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import getMetaTags from '../utils/seo'

import Layout from '../components/layout';
import PageTitle from '../components/page/title'
import ContactList from '../components/contact/list'
import ContactItem from '../components/contact/item'
import TeamList from '../components/team/list'
import TeamItem from '../components/team/item'

export const ContactPageTemplate = ({ title, intro, company, members }) => {
  const { name, phone, email, address, registry, accounts } = company

  const { street, municipality, county } = address
  const { registry_1, registry_2 } = registry
  const { account_1, account_2 } = accounts

  return (
    <div>
      <PageTitle title={title} />
      <ContactList intro={intro} company={name} phone={phone} email={email}>
        <ContactItem list={[street, municipality, county]} />
        <ContactItem list={[registry_1, registry_2]} />
        <ContactItem list={[account_1, account_2]} />
      </ContactList>
      <TeamList title='Meeskond'>
        {members.map((person, index) =>
          <TeamItem key={index} person={person} />
        )}
      </TeamList>
    </div>
  )
}

const ContactPage = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data

  const { frontmatter: { title, meta, company }, html: intro } = markdownRemark
  const members = allMarkdownRemark ? allMarkdownRemark.edges
    .map(({ node: { frontmatter } }) => frontmatter) : []

  return (
    <Layout>
      <Helmet title={'Kontakt'} meta={getMetaTags(meta)} />
      <ContactPageTemplate
        title={title}
        intro={intro}
        company={company}
        members={members}
      />
    </Layout>
  )
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta {
          description
          tags
        }
        company {
          name
          phone
          email
          address {
            street
            municipality
            county
          }
          registry {
            registry_1
            registry_2
          }
          accounts {
            account_1
            account_2
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___created] },
      filter: { frontmatter: { templateKey: { eq: "_ContactMemberTemplate" } }}
    ) {
      edges {
        node {
          html
          frontmatter {
            avatar {
              childImageSharp {
                fixed(width: 492, height: 492) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
            position
            phone
            email
          }
        }
      }
    }
  }
`
