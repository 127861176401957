import React from 'react'

import './item.scss'

const TeamItem = ({ person: { avatar, title, position, phone, email } }) => (
  <div className='b-team-item'>
    <div className='b-team-item-main'>
      <div className='b-team-item-main-avatar'>
        <figure>
          <img src={avatar.childImageSharp.fixed.src} />
        </figure>
      </div>
      <div className='b-team-item-main-name'>
        <h3>{title}</h3>
      </div>
      <div className='b-team-item-main-position'>
        <p>{position}</p>
      </div>
      <div className='b-team-item-main-tel'>
        <a href={'tel:' + phone.replace(/ /g, '')}>{phone}</a>
      </div>
      <div className='b-team-item-main-email'>
        <a href={'mailto:' + email}>{email}</a>
      </div>
    </div>
  </div>
)

export default TeamItem
