import React from 'react'

import './item.scss'

const ContactItem = ({ list }) => (
  <div className='b-contact-main-info-item'>
    {list.map((title, key) => <p key={key}>{title}</p>)}
  </div>
)

export default ContactItem
