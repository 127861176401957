import React from 'react'

import './list.scss'

const ContactList = ({ intro: __html, children, company, phone, email }) => (
  <div className='b-contact'>
    <div className='l-main'>
      <div className='b-contact-main'>
        <div className='b-contact-main-info'>
          <div className='b-contact-main-info-item'>
            <p className='b-contact-main-info-item-title'>{company}</p>
          </div>
          {children}
        </div>
        <div className='b-contact-main-info'>
          <div className='b-contact-main-info-item'>
            <div className='b-contact-main-info-item-title' dangerouslySetInnerHTML={{ __html }} />
          </div>
          {
            !phone ||
              <div className='b-contact-main-info-item'>
                <p>Tel: <a href={'tel:' + phone.replace(/ /g, '')} className='m-tel'>{phone}</a></p>
              </div>
          }
          {
            !email ||
              <div className='b-contact-main-info-item'>
                <p>E-mail: <a href={'mailto:' + email} className='m-email'>{email}</a></p>
              </div>
          }
        </div>
      </div>
    </div>
  </div>
)

export default ContactList
